import axiosInstance from "../api/withJwtServer";
import { actionTypes } from "../actionTypes";
import { createAsyncThunk } from '@reduxjs/toolkit';

interface getVehiclesParams {
  current_date: string;
  gate_id : string;
  kiosk_id : string;
}

export const getVehicles = createAsyncThunk(actionTypes.GET_VEHICLES, async (params : any, thunkAPI) => {
try {
    const response = await axiosInstance.post('/kiosk-get-booking-vehicles',params);
    console.log('response of login', response);

    return response;
} catch (e) {
    return thunkAPI.rejectWithValue(e);
}
});