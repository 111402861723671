import React from 'react';
import Seat from './Seat';

interface Props {
    map: any;
    onSeatClicked: any;
}

const SeatMap = (props: Props) => {
    var divRows = [];
    var key = 0;

    for (var rowIndex in props.map.rows) {
        var divSeats = [];
        var row = props.map.rows[rowIndex];
        key++;

        for (var seatIndex in row.seats) {
            var seat = row.seats[seatIndex];
            divSeats.push(
                <div className="cell" key={`seat-${rowIndex}-${seatIndex}`}>
                    <Seat
                        id={seat.seat_number} // Change to 'id' to match the Seat component's prop
                        status={seat.status}
                        type={seat.type}
                        onClick={props.onSeatClicked} // Pass the correct handler
                        seatNumber={seat.seat_number}
                        seatName={seat.seat_name}
                    />
                </div>,
            );
        }
        divRows.push(
            <div key={'row' + key} className="rows">
                {divSeats}
            </div>,
        );
    }

    return <div className="table">{divRows}</div>; // Return the actual seat map
};

export default SeatMap;
