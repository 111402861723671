import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';




function ConfirmDeleteModel(props) {


  const style = {
    SendSmsNo: {
      backgroundColor: "red",
      borderRadius: 5,
      borderColor: "red"
    },
    delete: {
      backgroundColor: "green",
      borderRadius: 5,
      borderColor: "green"

    },
    sendSmsConfirm: {
      backgroundColor: "green",
      borderColor: "green"
    },
    cancelbooking: {
      backgroundColor: "red",
      borderColor: "red"
    }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" mt-2 style={{fontWeight: 500, fontSize: 18, }}>
          {props.sendSms ? `Booking No-${props.booking_number}` :
            'Cancel Booking'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {props.sendSms ? 'Are you sure to send Url?' :
            'Are you sure you want to cancel this booking?'}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button  style={props.sendSms ? style.SendSmsNo : style.delete} onClick={props.onHide}>No</Button>
        <Button  style={props.sendSms ? style.sendSmsConfirm : style.cancelbooking} onClick={props.handleDelete}>
          {props.sendSms ? 'Yes, Send' : 'Yes, Cancel'}
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDeleteModel

