import Home from "../src/pages/home";
// import DateSelectionPage from "../src/pages/DateSelection";
import ValidateMobile from "../src/pages/validateMobile";
import OtpVerification from "../src/pages/otpVerification";
import VehicleSelection from "../src/pages/vehicleSelection";
import ConfirmTickts from "../src/pages/confirmTickts";
import ResendTicket from "./pages/resendTicket";
import MobileNumber from "./components/MobileNumber/MobileNumber";
import AdminLogin from '../src/pages/adminLogin';
import ProtectedRoute from '../src/ProtectedRoutes'
import ProcessPayment from './pages/ProcessPayment'
import PaymentSuccess from './pages/PaymentSuccess'
import PaymentFailed from './pages/PaymentFailed'
import PayementStatus from "./pages/PayementStatus";
import BookingList from "./pages/BookingList";
import React, { Suspense } from 'react'
import LazyLoadingComponent from "./components/LazyLoadingComponent/LazyLoadingComponent";
import VerifyOTP from "./components/OTPVerification/VerifyOTP";



    const isLogged = localStorage.getItem('isLogged');
    console.log('isLogged', isLogged);
    const DateSelectionPage = React.lazy(()=>import('../src/pages/DateSelection'))

    const routes =()=> [
        { path: '/', element : <Home /> },
        { path: '/date-selection', element:(<Suspense fallback={<LazyLoadingComponent/>}> <DateSelectionPage /></Suspense>) },
        { path: '/validateMobile', element: <ValidateMobile /> },
        { path: '/otpVerification', element: <VerifyOTP /> },
        { path: '/vehicleSelection', element: <VehicleSelection /> },
        { path: '/confirmTicket', element: <ConfirmTickts /> },
        { path: '/validateNumber', element: <MobileNumber /> },
        { path: '/resendTicket', element: <ResendTicket /> },
        { path: '/adminLogin', element: <AdminLogin /> },
        { path: '/processPayment', element: <ProcessPayment /> },
        { path: '/PaymentSuccess', element: <PaymentSuccess /> },
        { path: '/PaymentFailed', element: <PaymentFailed /> },
        { path: '/paymentStatus', element: <PayementStatus /> },
        { path: '/bookingList', element: <BookingList /> },
      ];
      export default routes;