import React, { useState, useEffect } from "react";
import SeatMap from "./SeatMap";
import ShowMessage from "../../ShowMessage/ShowMessage";

interface Props {
  initialMap: any;
  onMapUpdate: (updatedMap: any) => void;
  bookedSeats : any;
}

const Canvas = (props: Props) => {
  // Use state to track the seat map
  const { initialMap, onMapUpdate, bookedSeats } = props;
  const [seatMap, setSeatMap] = useState(initialMap);
  const [isShow, setIsShow] = useState(false)
  const [errorMessage,setErrorMessage] = useState("");

  useEffect(() => {
    setSeatMap(initialMap);
  }, [initialMap]);

  useEffect(() => {
  }, [bookedSeats]);

  const handleErrorMessageShow = (msg) =>{
      setIsShow(true);
      setErrorMessage(msg);
   setTimeout(() => {
    setIsShow(false);
  }, 2000);
  return; // Prevent booking confirmation if validation fails
}

  function onSeatClicked(id: number, newStatus: number) {
    let windowSeatCount = 0;
    bookedSeats?.map((value,index)=>{
      if(value.seat_number===1 || value.seat_number===3 || value.seat_number===4 || value.seat_number===6 || value.seat_number===7 || value.seat_number===9){
        windowSeatCount = windowSeatCount+1;
      }
    })
    console.log("NEW STATUS",newStatus);
    if(newStatus===0){
      const updatedMap = {
        ...seatMap,
        rows: seatMap.rows.map((row: any) => ({
          ...row,
          seats: row.seats.map((seat: any) =>
            seat.seat_number === id ? { ...seat, status: newStatus } : seat
          ),
        })),
      };
      setSeatMap(updatedMap);
      onMapUpdate(updatedMap)
    }
    else {
      if(bookedSeats.length < 6 ) {
        if(id ===1 || id===3 || id===4 || id===6 || id===7 || id===9) {
          if(windowSeatCount < 4){
            const updatedMap = {
              ...seatMap,
              rows: seatMap.rows.map((row: any) => ({
                ...row,
                seats: row.seats.map((seat: any) =>
                  seat.seat_number === id ? { ...seat, status: newStatus } : seat
                ),
              })),
            };
            setSeatMap(updatedMap);
            onMapUpdate(updatedMap)
          }
          else {
            handleErrorMessageShow("only 4 windows seats can be booked.");
          }
        }
        else {
          const updatedMap = {
            ...seatMap,
            rows: seatMap.rows.map((row: any) => ({
              ...row,
              seats: row.seats.map((seat: any) =>
                seat.seat_number === id ? { ...seat, status: newStatus } : seat
              ),
            })),
          };
          setSeatMap(updatedMap);
          onMapUpdate(updatedMap)
        } 
      }
      else {
        handleErrorMessageShow("Maximum 6 seats can be booked at a time");
      }
    }
    
   
  }

  return (
    <section>
      <ShowMessage
          onClose={() => setIsShow(false)}
          title={errorMessage}
          type={'error'}
          open={isShow}
        />
      <div className="card">
        <div className="card-body px-2">
          <SeatMap map={seatMap} onSeatClicked={onSeatClicked}/>
        </div>
      </div>
    </section>
  );
};

export default Canvas;
