import React from "react";
import tadobaLogo from "../../assets/tadoba-logo.png";
type Props = {};

const PageHeader = (props: Props) => {
  return (
    <>
      <div className="card-header bg-white border-0">
        <div className="d-flex justify-content-center">
          <img src={tadobaLogo} alt="Tadoba National Park Logo" width={90} />
        </div>
        <div className="text-center mt-3">
          <p className="mb-2">Book Safari Tickets</p>
          <h1 className="mb-2">Tadoba National Park</h1>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
