import React from 'react'

const LazyLoadingComponent = () => {
  return (
    <div style={style.container}>
        <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
      
    </div>
  )
}

export default LazyLoadingComponent
const style = {
    container: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
  };