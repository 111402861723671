import axiosInstance from "../api/WithFormDataJwtServer";
import { actionTypes } from "../actionTypes";
import { createAsyncThunk } from '@reduxjs/toolkit';

interface loginParmas{
    formData: string
  
  }



export const LoginAction = createAsyncThunk(actionTypes.LOGIN, async ( params: any, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/kiosk-login', params);
      console.log('response of Login', response.data);
  


    if(response.data.data){
      console.log("responselogin",response)
        localStorage.setItem('token', response.data.data.access_token)
    }
    return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });