import axiosInstance from "../api/withJwtServer";
import otpServer from '../api/otpServer'
import { actionTypes } from "../actionTypes";
import { createAsyncThunk } from '@reduxjs/toolkit';

interface VerifyMobileParams {
  mobile_number: string;
}

interface varifyOtpParams{
  username: string;
  password:string
  otp: string

}

export const VerifyMobileAction = createAsyncThunk(actionTypes.VERIFY_NUMBER, async ( params: VerifyMobileParams, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/kiosk-get-whats-app-number', params);
      console.log('response of login', response);
      // HandleExceptionWithSecureCatch(response);
  
    //   if (response.data.data[0]) {
    //     localStorage.setItem('token', response.data.data[0].token);
    //   }
  
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

  export const verifyOtpAction = createAsyncThunk(actionTypes.VERIfY_OTP, async ( params: any, thunkAPI) => {
    try {
      const response = await otpServer.post('/verify-otp', params);
      console.log('response of otp', response);

      if(response.data.data){
        console.log("responselogin",response)
          localStorage.setItem('token', response.data.data.access_token)
      }
  
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });