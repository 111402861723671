import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionTypes} from '../actionTypes';
import axiosInstance from '../api/withJwtServer';

//-------------------Get Kiosk Action------------------------
export const getDayTime = createAsyncThunk<any>(
    actionTypes.GET_DAY_TIME,
    async ( thunkAPI: any) => {
        try {
            const response = await axiosInstance.get(`/kiosk-ticket-booking-dates`);
            console.log("responseAction", response)
            return response;
           
        } catch (e) {
            console.log(e)
            return thunkAPI.rejectWithValue(e);
        
        }
    },
);
