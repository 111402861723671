import moment from "moment";
import React from "react";

type Props = { vehicleNumber: string; avaialableCount: number; time: string; onClick:()=>void , isActive:any};

const AvailableVehicle = ({ vehicleNumber, avaialableCount, time , onClick, isActive}: Props) => {
  const formattedTime = moment(time, 'HH:mm:ss').format('hh:mm A');
 
  return (
    <div className="col-2 text-center my-2">
      <div className={`card shadow-sm availableBlock ${isActive ? "active" : ""}`} onClick={onClick}>
        <div className="card-body px-1" >
          <h6 className="text-uppercase textSecondary text-small">
            {vehicleNumber}
           
          </h6>
          <h6>
            <strong>Available {avaialableCount}</strong>
          </h6>
          <p className="mb-0 textSecondary">{formattedTime}</p>
        </div>
      </div>
    </div>
  );
};

export default AvailableVehicle;
