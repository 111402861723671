import Form from 'react-bootstrap/Form';

function ToggleButton({onClick, checked}) {

  return (
    <Form>
      <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        onClick={onClick}
        checked={checked}
        style={{width: 100, borderColor: "blue"}}
  className="custom-switch"
          // label="Check this"
      />
    </Form>
  );
}

export default ToggleButton;