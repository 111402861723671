import React,{useEffect} from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
interface Props {
  seatNumber: number;
  name: string;
  age: string;
  gender: string;
  // handleSubmit:()=>void;
  onPassengerChange: (seatNumber: number,
    name: string,
    age: string,
    gender: string) => void;
  onValidate: any; // New prop for validation
}






const PassengerInfo = ({ seatNumber, name, age, gender, onPassengerChange, onValidate }: Props) => {
  const optionGender = [
    { id: 1, value: 'Male' },
    { id: 2, value: 'Female' },
    { id: 3, value: 'Other' },
  ];

  const formik = useFormik({
    initialValues: {
      name,
      age,
      gender,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is Required"),
      age: Yup.number()
        .required("Age is Required")
        .min(5, "Age must be 5 or greater")
        .max(100,'Age must be between 5 to 100')
        .positive("Age must be a positive number")
        .integer("Age must be an integer"),
     
      gender: Yup.string().required("Gender is Required"),
    }),
    onSubmit: (value) => {
//  console.log("value", value)
//  onPassengerChange(seatNumber, value.name, value.age, value.gender);
    },
  });

  // Update passenger details on change
  useEffect(() => {
    onPassengerChange(seatNumber,  formik.values.name, formik.values.age, formik.values.gender);
  }, [formik.values, seatNumber]);

  // Handle validation on confirm
  React.useEffect(() => {
    if (onValidate) {
      onValidate();
    }
  }, [formik.touched, formik.errors]);


  const { errors, touched, getFieldProps } = formik;
  const handleNameChange = (e) =>{
    formik.setFieldValue('name',e.target.value)
    }
    

  return (
    <div className="d-flex gap-2 my-3">
      <div>
      <input
        type="text"
        className={`form-control`}
        placeholder="Passenger Name"
        name="name"
        value={formik.values.name}
        onChange={(e) =>{formik.handleChange(e) 
        handleNameChange(e)
        }}
             onBlur={formik.handleBlur}
        // {...getFieldProps('name')}
      />
        {touched.name && errors.name ? (
                        <div style={{ color: "red", marginLeft:10}}>
                          {errors.name}
                        </div>
                      ) : null}
      </div>
      <div>
      <input
      type="tel"
       className={`form-control`}
        placeholder="Enter Age"
        name="age"
        {...getFieldProps('age')}
      />
        {touched.age && errors.age ? (
                        <div style={{ color: "red", marginLeft:10}}>
                          {errors.age}
                        </div>
                      ) : null}

       </div>
       <div>
      
      <select
        name="gender"
        className={`form-select`}
      defaultValue=""
      {...getFieldProps('gender')}
      >
        <option value="" disabled>Select Gender</option>
        {optionGender.map(genderOption => (
          <option key={genderOption.id} value={genderOption.value}>{genderOption.value}</option>
        ))}
      </select>
      {touched.gender && errors.gender ? (
                        <div style={{ color: "red",marginLeft:10}}>
                          {errors.gender}
                        </div>
                      ) : null}

    </div>
    </div>
  );
};

export default PassengerInfo;
