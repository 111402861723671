      
import React,{useState,useEffect } from 'react'
import PageHeader from '../components/ValidationPageComponent/PageHeader'
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import ToggleButton from '../components/Switch/ToggleButton';
import ConfirmDeleteModel from '../components/Model/ConfirmDeleteModel';
import { Navigate, useNavigate } from 'react-router';
import { UseSelector,useDispatch } from "react-redux";
import { RootState, AppDispatch } from '../Redux-Store/store';
import { useSelector } from "react-redux";
import ConfirmResendBookingCard from '../components/Card/ConfirmResendBookingCard'
import { useLocation } from 'react-router';
import {BookedTicketSmsAction} from '../Redux-Store/actions/ResendTicketAction'
import ShowMessage from '../components/ShowMessage/ShowMessage'
import moment from 'moment';

const BookingList = () => {
    const navigate = useNavigate()
    const [isShow, setIsShow] = useState(false)
    const [modalShow,setModalShow] = useState(false)
    const [navigateAfterMessage, setNavigateAfterMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [messageType, setMessageType] = useState<'success' | 'error'>('success')
    const [isChecked, setIsChecked] = useState()
    const [bookingNumber, setBookingNumber] = useState()
    const { resendTicketData, message } = useSelector((state: RootState) => state.resendTicket);
    console.log("resendTicketData", resendTicketData)
    console.log("message", message)
    const location =useLocation()
    const dispatch :AppDispatch =useDispatch()
    console.log("location", location?.state[0]?.booking_number)
    const booking_number =  location?.state[0]?.booking_number
    const mobile_number = location.state[0]?.mobile_number




    const handleModelClick =(index,booking) =>{
      console.log("booking123 : ",booking?.booking_number)
      console.log('MobileNumber', booking?.mobile_number)
      setBookingNumber(booking?.booking_number)
      setMobileNumber(booking?.mobile_number)
      setIsChecked(index)
        setModalShow(true)
      }


    const handleCancelBooking =() =>{
        setModalShow(true)
        const sendSmsObj ={
          mobile_number:mobileNumber ,
          booking_number: bookingNumber
        }
        dispatch(BookedTicketSmsAction(sendSmsObj)).then((response: any)=>{
          console.log("response",response)
          
          if(response.payload.meta.is_error === false){
            setErrorMessage(response?.payload?.meta?.message);
            setMessageType('success');
            setIsShow(true);
            setNavigateAfterMessage(true);
          }else if (response?.payload.meta.is_error === true) {
        
            setMessageType('error')
            setErrorMessage(response?.payload?.meta?.message);
            setIsShow(true);
            setNavigateAfterMessage(false);
          } else {
            console.log('else');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    setTimeout(() => {setIsShow(false)}, 2000);
      }

    const handleOnHide  =() =>{
      setIsChecked(null)
      setModalShow(false)
    }

    

    const handleConfirm  =() =>{
     const smsObj={
        mobile_number: mobile_number,
        booking_number: booking_number
      }
      dispatch(BookedTicketSmsAction(smsObj)).then((response: any)=>{
        console.log("response", response)
          if(response.payload.meta.is_error === false){
            setErrorMessage(response?.payload?.meta?.message);
            setMessageType('success');
            setIsShow(true);
            setNavigateAfterMessage(true);
            // navigate('/')
        }else if (response?.payload.meta.is_error === true) {
    
        setMessageType('error')
        setErrorMessage(response?.payload?.meta?.message);
        setIsShow(true);
        setNavigateAfterMessage(false);
      } else {
        console.log('else');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
setTimeout(() => {setIsShow(false)}, 2000);
  }
    


    const handleCancel =() =>{
navigate('/')
    }


    useEffect(() => {
      if (navigateAfterMessage) {
        const timer = setTimeout(() => {
          navigate('/');
        }, 2000);
  
        return () => clearTimeout(timer);
      }
    }, [navigateAfterMessage, navigate]);
  return (
    <div>
        
      
      {/* <section className="container validationPage">*/}
      <div className="row d-flex justify-content-center align-items-center height100">
        <div className="col-md-8">
          <div className="card"> 
            <PageHeader />
      <div className="card-body">
        <div className="d-flex justify-content-center">
        { resendTicketData?.total_count !== 1 ?
        (
 
      
    <Table striped bordered hover  style={{ width: '700px' , margin: '0 auto'  }}>
      <thead>
        <tr>
          <th  style={{ textAlign: 'center' }}>#</th>
          <th  style={{ textAlign: 'center' }}>Date</th>
          <th  style={{ textAlign: 'center' }}>Booking Number</th>
          <th  style={{ textAlign: 'center' }}>Send Request</th>
        </tr>
      </thead>
      <tbody>
        {resendTicketData?.data?.map((booking, index) => {
          const formatedDate= moment(booking.booking_date).format('DD MMM YYYY')
return( <tr  key={index}>
  <td  style={{ textAlign: 'center' ,fontWeight: 500, fontSize: 18,}}>{index+1}</td>

  <td  style={{ textAlign: 'center' }}>{formatedDate}</td>
  <td  style={{ textAlign: 'center',fontWeight: 500, fontSize: 15, }}>{booking.booking_number}</td>
  <td  style={{ textAlign: 'center' }}>
    <ToggleButton onClick={()=>handleModelClick(index,booking)} checked={isChecked === index}/>
  </td>
</tr>

)
       
       
       })}
       
      </tbody>


      <ConfirmDeleteModel
      sendSms
      booking_number={bookingNumber}
      show={modalShow}
        onHide={handleOnHide}
        handleDelete={handleCancelBooking}
      />
    </Table>
        //     </div></div></section>
    // </div>
  
  ):
    <div>
        <ConfirmResendBookingCard
        message={'Are You Sure to Send Url?'}
        Booking_number={booking_number}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        />
        </div>
    
    }
        </div>
        </div>
        {/* </div></div></div></section> */}
        <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={messageType}
        open={isShow}
      />
        
        
        </div>
</div>
</div>
</div>
        

  )
}

export default BookingList
