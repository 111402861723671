import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { CheckCircle, ExclamationCircle } from 'react-bootstrap-icons'; // Bootstrap icons



interface ShowMessageProps {
  open: boolean;          // Whether the toast should be shown or not
  title: string;          // The title of the toast
  onClose: () => void;    // Callback function to close the toast
  type: 'success' | 'error';
}
const ShowMessage: React.FC<ShowMessageProps> = (props) => {
  const handleClose = () => {
    props.onClose();
  };




  return (
    <ToastContainer position="top-center" className="p-3">
      <Toast
        show={props.open}
        onClose={handleClose}
        bg={props.type === 'success' ? 'success' : 'danger'}
        className="text-white rounded-pill"
      >
        {/* <Toast.Header closeButton> */}
        <Toast.Body className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
            {props.type === 'success' ? (
              <CheckCircle className="me-2" size={20} />
            ) : (
              <ExclamationCircle className="me-2" size={20} />
            )}
            {props.title}
          </div>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            // onClick={onClose}
          />

          {/* Optional body content if needed */}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ShowMessage;