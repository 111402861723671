import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function ConfirmResendBookingCard({message, Booking_number, handleConfirm, handleCancel}) {
  return (
    <div  style={{display: "flex", alignItems: "center", justifyContent: "center", }} >
    <Card  className="shadow-lg" style={{ width: '500px', marginBottom: 20}} >
    <Card.Header><p  mt-2 style={{fontWeight: 500, fontSize: 18, }}>Booking No - {Booking_number}</p></Card.Header>
    <Card.Body>
      {/* <Card.Title>Special title treatment</Card.Title> */}
      <Card.Text>
      <p style={{fontWeight: 400, fontSize: 18}}>
      {message}
      </p>
      </Card.Text>
    </Card.Body>
    <Card.Footer>
   <div style={{display:"flex", flexDirection: "row", justifyContent:'flex-end', gap: 6}}>
      <Button  variant="danger" onClick={handleCancel}>No</Button>
      <Button variant="success" onClick= {handleConfirm}>Yes, Send</Button>
      </div>
      </Card.Footer>
  </Card>
  </div>
  );
}

export default ConfirmResendBookingCard;