import { createSlice } from '@reduxjs/toolkit';
import { ResendTicketAction } from '../actions/ResendTicketAction';

const ResendTicketSlice = createSlice({
  name: 'resendTicketData',
  initialState: {
    resendTicketData: null,
    loading: false,
    error: null,
    message: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ResendTicketAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(ResendTicketAction.fulfilled, (state, action) => {
        state.loading = false;
        state.resendTicketData = action.payload
        state.message = action?.payload?.meta?.message
        console.log("state.message",  state.message)
      })
      .addCase(ResendTicketAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error
      });
  },
});

export default ResendTicketSlice.reducer;