import axiosInstance from "../api/withJwtServer";
import { actionTypes } from "../actionTypes";
import { createAsyncThunk } from '@reduxjs/toolkit';



export const TicketPaymentAction = createAsyncThunk(actionTypes.CONFIRM_BOOKING_DETAILS, async ( params: any, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/kiosk-ticket-payment-response', params);
      console.log('response in confirm ticket booking', response);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });