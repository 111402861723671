import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {LoginAction } from '../actions/LoginAction'



interface loginData{
    login: any;
    error: string | null;
    isLoading: boolean;
    message:string;
    is_error: boolean
    role: string
    isLoggedIn: any,
    kiosk_id: number
    
}

const initialState: loginData = {
  login: null,
  error: '',
  isLoading: false,
  message:'',
  is_error: false,
  role: '',
  isLoggedIn: false,
  kiosk_id: null
};

const Authentication = createSlice({
  name: 'login',
  initialState,

  reducers: {
    // clearError(state) {
    //   state.error = undefined;
    // },
  },

  extraReducers: (builder) => {
    builder.addCase(LoginAction.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(LoginAction.fulfilled, (state, action: PayloadAction<any>) => {
      console.log('action.payload123', action.payload);
      if(action.payload.meta.is_error){
        state.isLoading = false;
        state.login = null;
      }
      else {
        state.login = action.payload.data
        state.message= action.payload.meta.message
        // state.kiosk_id= action.payload.data.kiosks[0].kiosk_id
        // console.log("state.kiosk_id",  state.kiosk_id)
        state.isLoggedIn = true;
        console.log('message', state.message);
        state.login=localStorage.getItem('login')
        const parsedData = state.login ? JSON.parse(state.login) : null;
      localStorage.setItem('login', JSON.stringify(state.login));
       localStorage.setItem('login', parsedData);
       console.log("state.loginauth",state.login)
      }
      
    });
    builder.addCase(LoginAction.rejected, (state, action) => {
      console.log('action.payload in login ', action.payload);
      state.isLoading = false;
      state.login = null;
    });

    // ------------Logout reducer------------------
  },
});

export default Authentication.reducer;
