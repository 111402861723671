import axios from 'axios';
 
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASED_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Language': 'browser',
    },
});
 
axiosInstance.interceptors.request.use((config) => {
    // const token = localStorage.getItem("token");
    // config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});
 
axiosInstance.interceptors.response.use(undefined, (error) => {
    const responseError = error.response;
    if (responseError.status === 401 && error.config && !error.config.__isRetryRequest) {
        //redirect to login page
        //clear store and storage data
        return (window.location.href = '/');
    } else {
        return Promise.reject(error);
    }
});
 
export default axiosInstance;
 
 