import React, { useState, useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import tadobaLogo from '../assets/tadoba-logo.png'
import { useLocation } from 'react-router';

const ProcessPayment = () => {  
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);


  const location = useLocation()
  console.log("data", location)

  const dataUrl = location.state.data

  useEffect(() => {
    const fetchData = () => {
      
      setTimeout(() => {
        setIsLoading(false); 
        submitPayment();
      }, 2000);
    };

    // submitPayment();


    fetchData()
  }, []);


  const submitPayment = () => {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", "https://merchant.onlinesbi.sbi/merchant/merchantprelogin.htm");
    // form.setAttribute("target", "_blank");

    // Hidden Fields
    const hiddenField1 = document.createElement("input");
    hiddenField1.setAttribute("type", "hidden");
    hiddenField1.setAttribute("name", "encdata");
    hiddenField1.setAttribute("value", dataUrl);

    const hiddenField2 = document.createElement("input");
    hiddenField2.setAttribute("type", "hidden");
    hiddenField2.setAttribute("name", "merchant_code");
    hiddenField2.setAttribute("value", "MAHA_ECO");

    form.appendChild(hiddenField1);
    form.appendChild(hiddenField2);

    document.body.appendChild(form);
    
    form.submit();
  };


  




  return (
    <>
     <div className="card-header bg-white border-0">
        <div className="d-flex justify-content-end ml-20">
          <img src={tadobaLogo} alt="Tadoba National Park Logo" width={90} />
        </div>
    <div className="process-payment-summary">
      
    {isLoading && (
        <div className="spinner-border spinner-border-lg text-success"  style={{width: 50, height: 50}}role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        // <ProgressBar striped variant="success"  />
      )}
    </div>
    </div>
    </>
  );
};

export default ProcessPayment;