import { createSlice } from '@reduxjs/toolkit';
import {KioskGateAction  } from '../actions/KioskGateAction';

const gateSlice = createSlice({
  name: 'gates',
  initialState: {
    gates: [],
    loading: false,
    error: null,
    gateName: ''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(KioskGateAction.pending, (state,action) => {
        state.loading = true;
        state.gates=  [] 
        // state.error = action.error.message;
      })
      .addCase(KioskGateAction.fulfilled, (state, action) => {
        state.loading = false;
        state.gates = action.payload.data; // Store the data in the Redux state
        console.log("state.gates",state.gates)
        // state.gateName= action.payload.data.gate_name
        // console.log("state.gateName", state.gateName)
      })
      .addCase(KioskGateAction.rejected, (state, action) => {
        state.loading = false;
        state.gates=  [] 
        state.error = action.error.message;
      });
  },
});

export default gateSlice.reducer;