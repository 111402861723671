import React,{useEffect, useState} from 'react'
import { useNavigate } from 'react-router';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { AppDispatch } from '../Redux-Store/store';
import {TicketPaymentAction} from '../Redux-Store/actions/TicketPaymentAction'
import tadobaLogo from '../assets/tadoba-logo.png'

const PayementStatus = () => {
  const navigate= useNavigate()
  const dispatch: AppDispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true);

const [queryParameters] = useSearchParams();

 

const requestData = {
  encData : queryParameters.get("encdata"),
}
console.log("requestData", requestData)
console.log("requestadata",JSON.stringify(requestData));

  const handlePaymentStatus = () => {

    if (requestData.encData === null) {
      console.error("encdata is null");
      setIsLoading(false);
      navigate("/PaymentFailed");
      return;
    }
    dispatch(TicketPaymentAction({
      encdata: requestData,

    })).then((response: any)=>{
      console.log("response", response)
     checkAndNavigate(response);
     setIsLoading(false)
    }

  ).catch((error)=>{
    console.log("error",error)
  })
}


const checkAndNavigate = (response)=>{
  if(response.data){
  let data = response.data;
    console.log("data",data);

    let splitData = data.split("|");
    console.log("splitedData",splitData[3]);

    let statusData = splitData[3];

    let splitStatus = statusData.split("=")[1];

  
    console.log("Status of trasn ",splitStatus);


    if(splitStatus.toLocaleLowerCase() === "Success".toLocaleLowerCase()){
      navigate("/PaymentSuccess");  
    }else{
      navigate("/PaymentFailed");
    }


  }
}

  useEffect(() => {
    handlePaymentStatus();
  }, []);
    
  return (
    <div>
         <div className="card-header bg-white border-0">
        <div className="d-flex justify-content-end ml-20">
          <img src={tadobaLogo} alt="Tadoba National Park Logo" width={90} />
        </div>
    <div className="process-payment-summary">
      
    {isLoading && (
        <div className="spinner-border spinner-border-lg text-success"  style={{width: 50, height: 50}}role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        // <ProgressBar striped variant="success"  />
      )}
    </div>
    </div>
    </div>
  )
}

export default PayementStatus
