import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionTypes} from '../actionTypes';
import axiosInstance from '../api/withJwtServer';

//-------------------Get Kiosk Action------------------------
export const kioskLogin = createAsyncThunk(
    actionTypes.KIOSK_LOGIN,
    async (params: any, thunkAPI: any) => {
        console.log("parms",params)
        try {
            const response = await axiosInstance.post(`/kiosk-login`,params);
            console.log("responseAction", response)
            const { kiosk_mac_id } = response.data;
            console.log("kiosk_mac_id", kiosk_mac_id )
            return response;
           
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    },
);
