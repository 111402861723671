import React,{useEffect} from 'react'
import PageHeader from '../components/ValidationPageComponent/PageHeader'
import tadobaLogo from '../assets/tadoba-logo.png'
import { useNavigate } from 'react-router';

import { MdOutlineErrorOutline } from "react-icons/md";
const PaymentFailed = () => {

  const navigate=  useNavigate()
  useEffect(()=>{
    setTimeout(()=>{
      navigate("/")
    },5000)
  })
  return (
    <div>
          <div style={{ marginTop: '60px' }}>
         <div className="card-header bg-white border-0">
        <div className="d-flex justify-content-center mt-300">
          <img src={tadobaLogo} alt="Tadoba National Park Logo"/>
          </div>
          </div>
          <div className='mt-5'>
          <div className="d-flex flex-column justify-content-center align-items-center" >
          <MdOutlineErrorOutline style={{width: 60, height: 60, color: "red"}}/>
          <div style={{color: "#7c7c7c", fontWeight: 600, fontSize: 50 }} className="mb-2">Your Booking is Cancelled</div>
          <div style={{color: "red", fontWeight: 600, fontSize: 25 }}>Your Payment Transaction is Declined</div>
        </div>
        </div>
        </div>
      
    </div>
  )
}

export default PaymentFailed
