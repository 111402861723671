import { kioskLogin } from './../actions/KioskAction';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

// Define the TypeScript interface for the state
interface DataState {
  kiosk: any; // Replace `any` with the actual type of your data if known
  error: string | null;
  isLoading: boolean
}

// Define the initial state with the TypeScript interface
const initialState: DataState = {
  kiosk: 'kiosk',
  error: null,
  isLoading: false
};

// Create the slice with TypeScript annotations
const kioskSlice = createSlice({
  name: 'kiosk',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(kioskLogin.pending, (state) => {
        state.isLoading = false
      })
      .addCase(kioskLogin.fulfilled, (state, action: PayloadAction<{ kiosk_mac_id: string }>) => {
        state.kiosk= action.payload.kiosk_mac_id

        state.isLoading= true;
        console.log("state.kiosk",  state.kiosk)
        console.log("state.data", state.kiosk)
      })
      .addCase(kioskLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  }
});

// Export the reducer
export default kioskSlice.reducer;