import { getVehicles } from './../actions/VehicleSelection';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

// Define the TypeScript interface for the state
interface DataState {
  vehicle: any; // Replace `any` with the actual type of your data if known
  error: string | null;
  isLoading: boolean
}

// Define the initial state with the TypeScript interface
const initialState: DataState = {
  vehicle: [],
  error: null,
  isLoading: false
};

// Create the slice with TypeScript annotations
const vehicleSelectionSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVehicles.pending, (state) => {
        state.isLoading = false
      })
      .addCase(getVehicles.fulfilled, (state, action: PayloadAction<any>) => {
        console.log("ACTION PAY<LOAD",action.payload)
        state.vehicle= action.payload.data.data
        state.isLoading= true;
      })
      .addCase(getVehicles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  }
});

// Export the reducer
export default vehicleSelectionSlice.reducer;