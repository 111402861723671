// reducers/verifyReducer.js
import { createSlice } from '@reduxjs/toolkit';
import { VerifyMobileAction } from '../actions/VerifyNumberAction';


interface VerifyMobileData{
    mobileVerification: null;
    error: string | null;
    isLoading: boolean;
    message:string;
    is_error: boolean
    
}


const initialState: VerifyMobileData ={
    mobileVerification: null,
    isLoading: false,
    error: '',
    message: '',
    is_error:false
}

const verifySlice = createSlice({
  name: 'mobileVerification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(VerifyMobileAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(VerifyMobileAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.mobileVerification = action.payload.data;
        state.message=action.payload.meta.message
        state.is_error= action.payload.meta.is_error
      })
      .addCase(VerifyMobileAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});



export default verifySlice.reducer;
