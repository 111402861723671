
import React, {useState, useRef , useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, FormikProvider, FieldArray } from 'formik';
import * as Yup from 'yup';
import {verifyOtpAction} from '../../Redux-Store/actions/VerifyNumberAction'
import { useLocation } from "react-router-dom";
import { UseDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState, AppDispatch } from '../../Redux-Store/store';
import ShowMessage from "../ShowMessage/ShowMessage"
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { MdOutlineErrorOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import nicLogo from "../../assets/nic.png";
import mahaForestLogo from "../../assets/mahaforestdept-logo.png";

// Validation schema for OTP
const otpValidationSchema = Yup.object({
  otp: Yup.array()
    .of(Yup.string().length(1, 'Each OTP field must be a single digit').required('OTP digit is required'))
    .length(4, 'OTP must be exactly 4 digits')
});

const VerifyOTP = () => {
  const navigate = useNavigate();
  const dispatch:AppDispatch = useDispatch()
  const [errorMessage,setErrorMessage] =useState('')
  const [isShow, setIsShow] = useState(false)
  const [navigateAfterMessage, setNavigateAfterMessage] = useState(false)
  const [messageType, setMessageType] = useState<'success' | 'error'>('success')
  const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' | null }>({ text: '', type: null });
  const [error, setError] = useState()


  // const location = useLocation();
  // console.log("location",location);

  const username= localStorage.getItem('username')
  const password = localStorage.getItem('password')
  console.log("username",username,password)

//   const { otpVerification, isLoading, messageOtp ,is_error} = useSelector((state: RootState) => state.otpVerification);
// console.log("otpVerification", otpVerification)
// console.log("messageOtp",messageOtp)
// console.log("is_error", is_error)




  



  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      otp: ["", "", "", ""]
    },
    validationSchema: otpValidationSchema,
    onSubmit: async (values) => {
      const otpString = values.otp.join("");
      if (/^\d{4}$/.test(otpString)) {
        console.log("otpString",otpString)
      }
      const obj={
        username: username,
        password: password,
        otp: otpString
      }
      console.log("obj",obj)
      dispatch(verifyOtpAction(obj)).then((response:any)=>{
        console.log('rrrrrr123', response);

        localStorage.setItem('kiosk_id',response?.payload?.data?.kiosks[0]?.kiosk_id)
        if (response.payload.meta.is_error===false) {

          setMessageType("success")
          setErrorMessage(response?.payload?.meta?.message);
          setMessage({ text: response?.payload?.meta?.message, type: 'success' });
          setIsShow(true);
          setNavigateAfterMessage(true);
          console.log('response', response?.data?.meta?.message);
        }else if (response?.payload.meta.is_error === true) {
          setMessageType('error')
          setErrorMessage(response?.payload?.meta?.message);
          setMessage({ text: response?.payload?.meta?.message, type: 'error' });
          setIsShow(true);
          setNavigateAfterMessage(false);
        } else {
          console.log('else');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    setTimeout(() => {setIsShow(false)}, 2000);
    }
  })

  const { values, handleChange, handleSubmit, errors, touched, setFieldValue } = formik;
  console.log("errors", errors)

  const otpRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;

    // Update OTP value in Formik
   console.log("setFieldValue", setFieldValue(`otp.${index}`, value))

    // Move to the next input if a value is entered
    if (value.length === 1 && index < otpRefs.length - 1) {
      otpRefs[index + 1]?.current?.focus();
    }

    // Move to the previous input if backspace is pressed
    if (value === "" && index > 0) {
      otpRefs[index - 1]?.current?.focus();
    }
  };


  useEffect(() => {
    if (navigateAfterMessage) {
      const timer = setTimeout(() => {
        navigate('/');
      }, 2000); // Wait for the message to be displayed

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [navigateAfterMessage, navigate]);


  const handleClick =() =>{
    navigate('/validateMobile')
 }

  return (
    <FormikProvider value={formik}>
       <main className="bgMain">
    <div className="container">
      <div className="row justify-content-center align-items-center height100">
        <div className="col-md-9 align-self-center">
          <div className="card card-transparancy">
            <div className="card-body">
              <div className="text-center">
                <img
                  src={mahaForestLogo}
                  alt="Maharashtra Forest Logo"
                  width={100}
                />
                     </div>
      {/* <div className="card-body"> */}
        <div className="d-flex justify-content-center">
          <form className="w-50" onSubmit={handleSubmit}>
            <p className="text-center textSecondaryLight">
              Please Enter OTP 
            </p>
            <div className="otp-inputs d-flex gap-2 justify-content-center">
              {values.otp.map((digit, index) => (
                <input
                  key={index}
                  type="tel"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleInputChange(e, index)}
                  ref={otpRefs[index]}
                  autoFocus={index === 0}
                  className="form-control"
                  style={{ width: '50px' }}
                />
              ))}
            </div>
            {errors.otp && touched.otp && (
              <p className="text-danger text-center mt-2">{errors.otp.includes(undefined) ? 'OTP must be exactly 4 digits' : 'OTP digit is required'}</p>
              
            )}
            <div className="mt-4">
           
            {errorMessage==='OTP matched!' ?
              <div style ={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <IoCheckmarkDoneCircleOutline style={{color: "green", width: 70, height:80}} />
                  <div style={{marginTop: 4, fontWeight: 500, fontSize: 12, color:"#0E3964"}}>verified
                </div>
              </div>:  errorMessage==='Invalid OTP!'? 
                <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                <MdOutlineErrorOutline style={{width: 60, height: 60, color: "red"}}/>
                <div style={{marginTop: 4, fontWeight: 500, fontSize: 12, color:"#0E3964"}}>Please enter correct OTP
                </div>
                <button type="button" style={{border: "1px solid #0E3964", color: "#0E3964", padding: 15, margin : 20, borderRadius: 5}} onClick={handleClick}>BACK TO VERIFY NUMBER</button>
                </div>:

              
              <>
              <button
                className="btnPrimary btn-lg w-100"
                type="submit"
              >
                Verify
              </button>
              <button
              onClick= {()=>{navigate('/adminLogin')}}
                className="btnSecondary btn-lg w-100 linkbtn-block mt-3"
              >
                Back
              </button>
              </>
              }
              
            </div>
            
          </form>
          <ShowMessage
          onClose={() => setIsShow(false)}
          title={errorMessage}
          type={messageType}
          open={isShow}
        />
  </div>
  </div>
  <div className="card-footer bg-white text-center">
                  <p className="mb-1 textLight">Powered by</p>
                  <img
                    src={nicLogo}
                    alt="National Informatics Center (NIC)"
                    width={100}
                    // onDoubleClick={handleAdminLogin}
                  />
                </div>
            
         
          </div>
        </div>
        </div>
        </div>
      </main>
    </FormikProvider>
  );
};

export default VerifyOTP