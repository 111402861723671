import React from "react";
import nicLogo from "../../assets/nic.png";
type Props = {};

const PageFooter = (props: Props) => {
  return (
    <div className="card-footer text-center mt-4 bg-white">
      <p className="mb-1 text-small">Note</p>
      <p className="text-small">
        Only WhatsApp numbers are allowed to book tickets
      </p>
      <p className="text-small textSecondary">Powered by</p>
      <img src={nicLogo} alt="NIC Logo" width={70} />
    </div>
  );
};

export default PageFooter;
