


import axiosInstance from "../api/withJwtServer";
import { actionTypes } from "../actionTypes";
import { createAsyncThunk } from '@reduxjs/toolkit';

interface resendTicketParams {
    mobile_number: any;
}

export const ResendTicketAction = createAsyncThunk(actionTypes.RESEND_TICKET, async ( params: resendTicketParams, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/kiosk-resend-booked-ticket', params);
      console.log('response of login', response);
  
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });


  export const BookedTicketSmsAction = createAsyncThunk(actionTypes.BOOKED_TICKET_SMS, async ( params: resendTicketParams, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/kiosk-booking-ticket-send-sms', params);
      console.log('response of login', response);
  
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });