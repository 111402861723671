import React, { useState , useEffect} from "react";
import PassengerDetails from "./PassengerDetails";
import { Link,  } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import ConfirmDeleteModel from "../Model/ConfirmDeleteModel"
import { UseDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../Redux-Store/store";
import { cancelBookingAction } from "../../Redux-Store/actions/CancelBookingAction";
import ShowMessage from '../ShowMessage/ShowMessage'



type Props = {};

const BookingDetails = ({visitorsName,bookedSeats,reg_no, bookingId,handleMorePassenger}) => {
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch();

  const [modalShow,setModalShow] = useState(false)
  const [errorMessage,setErrorMessage] =useState('')
  const [isShow, setIsShow] = useState(false)
  const [navigateAfterMessage, setNavigateAfterMessage] = useState(false)
  const [messageType, setMessageType] = useState<'success' | 'error'>('success')
  const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' | null }>({ text: '', type: null });
  const [error, setError] = useState()

  const selectedDate = localStorage.getItem('selectedDate')
const selectedGate = localStorage.getItem('selectedGate')


const formattedDate = moment(selectedDate).format('DD MMM YYYY')
console.log("formattedDate", formattedDate)

// const handleMorePassenger =() =>{
//   props.handleMorePassenger();
// }


  const handleMainPage = () =>{
    setModalShow(true)
    console.log("aaa")
    // navigate('/')
  }


const handleCancelBooking = () => {
  const payload ={  
    booking_id: bookingId,
    booking_status:"booking_cancelled" }
    dispatch(cancelBookingAction(payload)).then((response)=>{
      console.log("response", response.payload.meta.is_error=== false)
      if (response.payload.meta.is_error===false) {
        setMessageType("success")
        setErrorMessage(response?.payload?.meta?.message);
        setMessage({ text: response?.payload?.meta?.message, type: 'success' });
        setIsShow(true);
        setNavigateAfterMessage(true);
      }else if (response?.payload.meta.is_error === true) {
        setMessageType('error')
        setErrorMessage(response?.payload?.meta?.message);
        setIsShow(true);
        setNavigateAfterMessage(false);
      } else {
        console.log('else');
      }

    }) 
  }


  useEffect(() => {
    if (navigateAfterMessage) {
      const timer = setTimeout(() => {
        navigate('/');
      }, 2000); // Wait for the message to be displayed

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [navigateAfterMessage, navigate,]);



  return (
    <>
      <div className="bookingDetails">
        <h2>{selectedGate}</h2>
        <p>{formattedDate}</p>
        <div className="d-flex justify-content-between vehicleDetails">
          <span>{reg_no}</span>
          <span>Total Seats Booked {bookedSeats}</span>
        </div>
        <div className="passengerDetails mb-3">
        {visitorsName.map((passenger, id) => {
          return (
            <div className="passenger" key={id}>
              <div>
                <h5>{passenger.visitor_name}</h5>
              </div>
              <div>{passenger.visitor_age} Years</div>
              <div>{passenger.visitor_gender}</div>
            </div>
          );
        })}
      </div>
        <button  className="btnSecondary" onClick= {handleMorePassenger}>
          Add More Passengers
        </button>
        <button onClick ={ handleMainPage}className="btnDanger ms-4">
          Cancel Booking
        </button>

        <ConfirmDeleteModel    show={modalShow}
        onHide={() => setModalShow(false)}
        handleDelete={handleCancelBooking}
        />
      </div>

      
      <>   <ShowMessage
          onClose={() => setIsShow(false)}
          title={errorMessage}
          type={messageType}
          open={isShow}
        /></>
    </>
  );
};

export default BookingDetails;
