
export const actionTypes = {
KIOSK_LOGIN :'kiosk_login',
VERIFY_NUMBER: 'verify-number',
VERIfY_OTP:"verify_otp",
GET_DAY_TIME:'get_day_time',
GET_VEHICLES : 'get_vehicle',
LOGIN:'login',
KIOSK_GATE:'kiosk_gate',
CONFIRM_BOOKING_DETAILS: 'confirm_booking_details',
CONFIRM_TICKET: 'confirm_ticket',
CANCEL_BOOKING:'cancel_booking',
RESEND_TICKET:'resend_ticket',
BOOKED_TICKET_SMS:'booked_ticket_sms',
CLEAR_BOOKING_SEATS : 'clear_booking_seats',
UPDATE_CONFIRM_BOOKING_DETAILS: 'update_confirm_booking_details',
}