import React from "react";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/variables.css";
import "./styles/global.css";
import "./styles/general-page-style.css";
import { useRoutes } from "react-router-dom";
import routes from "./routes";

function App() {
  const routing = useRoutes(routes())
  return (
    // <ErrorBoundary fallback="There is some error in code">
    <div className="App">{routing}</div>
// </ErrorBoundary>
   
  );
}

export default App;
