import './seat.css';

interface Props {
    id: number;
    status: number;
    type: number;
    onClick: (id: number, status: number) => void;
    seatName: string;
    seatNumber: number;
}

export const seatTypes = {
    BLANK: 0,
    DRIVER: 1,
    GUIDE: 2,
    VISITOR: 3,
};

export const seatStatus = {
    OPEN: 0,
    BOOKED: 1, //orange
    DAMAGED: 2, //transparent
    BLOCKED: 3, //gray
    SELECTED: 4, // light-green
};

const onSeatClick = (
    id: number,
    status: number,
    type: number,
    onClick: (id: number, status: number) => void,
) => {
    if (type === seatTypes.VISITOR) {
        if (status === seatStatus.OPEN) {
            onClick(id, seatStatus.SELECTED);
        } else if (status === seatStatus.SELECTED) {
            onClick(id, seatStatus.OPEN);
        }
    }
};

const Seat = (props: Props) => {
    let classValue = '';
    let imageResource: string;

    switch (props.status) {
        case seatStatus.OPEN:
            classValue = 'seatOpen';
            break;
        case seatStatus.BOOKED:
            classValue = 'seatBooked';
            break;
        case seatStatus.DAMAGED:
            classValue = 'seatDamaged';
            break;
        case seatStatus.BLOCKED:
            classValue = 'seatBlocked';
            break;
        case seatStatus.SELECTED:
            classValue = 'seatSelected';
            break;
        default:
            classValue = 'seatOpen';
            break;
    }

    switch (props.type) {
        case seatTypes.BLANK:
            classValue = 'seatOpen';
            imageResource = require('./img/blank.png');
            break;
        case seatTypes.DRIVER:
            imageResource = require('./img/steer.png');
            break;
        case seatTypes.GUIDE:
            imageResource = require('./img/tour-guide.png');
            break;
        case seatTypes.VISITOR:
            imageResource = require('./img/seat.png');
            break;
        default:
            imageResource = require('./img/seat.png');
            break;
    }

    return (
        <div className="seat" onClick={() => onSeatClick(props.id, props.status, props.type, props.onClick)}>
            <img
                className={classValue}
                src={imageResource}
                alt=""
                width={40}
                height={40}
            />
            {props.seatNumber != -1 && <div className="seat-name">{props.seatNumber}</div>}
        </div>
    );
};

export default Seat;
