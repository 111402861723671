  import React, { useEffect, useState } from "react";
  import { Link, useNavigate, useLocation } from "react-router-dom";
  import logo from "../assets/tadoba-logo.png";
  import AvailableVehicle from "../components/VehicleDetails/AvailableVehicle";
  import PassengerInfo from "../components/VehicleDetails/PassengerInfo";
  import { MdOutlineArrowBack } from "react-icons/md";
  import { MdOutlineArrowForward } from "react-icons/md";
  import VehicleSeatMap from "../components/VehicleDetails/VehicleSeatMap";
  import { useDispatch } from "react-redux";
  import { AppDispatch, RootState } from "../Redux-Store/store";
  import { useSelector } from "react-redux";
  import { getVehicles } from "../Redux-Store/actions/VehicleSelection";
  import Canvas from "../components/VehicleDetails/SeatMapCanvas/Canvas";
  import { ConfirmBookingDetails, UpdateConfirmBookingDetails } from "../Redux-Store/actions/ConfirmBookingdetails";
  import ShowMessage from "../components/ShowMessage/ShowMessage";
  import moment from 'moment';
  import './../components/VehicleDetails/SeatMapCanvas/Seat';

  type Props = {};

  const VehicleSelection = (props: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { vehicle } = useSelector((state: RootState) => state.vehicle);
    const {kiosk_id} = useSelector((state: RootState)=> state.login)
    const [selectedSeatCount, setSelectedSeatCount] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [bookedSeats, setBookedSeats] = useState<any>([]);
    const [passengerDetails, setPassengerDetails] = useState<any[]>([]);
    const [isShow, setIsShow] = useState(false)
    const [errorMessage,setErrorMessage] =useState('');
    const [canvasMap, setCanvasMap] = useState<any>(null);
    const [plantId, setPlanId] = useState()
    const [vehicalSheduleID,setVehicalsheduleId] = useState()
    const [reg_no, setReg_no] = useState()

    console.log("planId", plantId)
console.log("selectedSeatCount",selectedSeatCount)
  const selectedDate = localStorage.getItem('selectedDate')
  console.log("selectedDate", selectedDate)
  const selectedGate = localStorage.getItem('selectedGate')


  const formattedDate = moment(selectedDate).format('DD MMM YYYY')
  console.log("formattedDate", formattedDate)

    const location= useLocation()
    console.log("location", location.state.mobileNumber)
    const mobile_number= location.state.mobileNumber
    console.log("location12",location)

  console.log("state", state)
  console.log("passengerDetails", passengerDetails)

  const kiosk_id_local = localStorage.getItem('kiosk_id')
  

  useEffect(()=>{
    if(state.bookingDetails){
      setReg_no(location.state.reg_no);
      
      setSelectedSeatCount(location.state.bookedSeats)
      const newPassengerDetails = []
      const newBookedSeats = [];
      location.state.visitors?.map((value,index)=>{
        newPassengerDetails.push({
          name : value.visitor_name,
          age : value.visitor_age,
          gender : value.visitor_gender,
          seatNumber : value.seat_number,
        })
        newBookedSeats.push({
          seat_number : Number(value.seat_number)
        })
      })
      setPassengerDetails(newPassengerDetails)
      setBookedSeats(newBookedSeats)
    }
  },[state])

    useEffect(() => {
      if (state && state.selectedDate && state.gateId) {
        const reqObj = {
          current_date: state.selectedDate,
          gate_id: state.gateId,
          kiosk_id: kiosk_id_local,
        };
        dispatch(getVehicles(reqObj)).then((response:any)=>{
          setPlanId(response?.payload?.data?.data.plan_id)
          console.log("plan iD",response?.payload?.data?.data)
          setVehicalsheduleId(response?.payload?.data?.data[0].vehicle_schedule_id)
          if(!location.state.bookingDetails) {
            setReg_no(response?.payload?.data?.data[0].reg_no)
          }

          const seatNumbersToUpdate = location.state?.visitors?.map(item => item.seat_number);
          
          if (response?.payload?.data?.data?.length > 0) {
            const seatData = response.payload.data.data[0];
            const { available_seat_status: availableSeatStatus, seat_map_template: initialMap } = seatData;
            console.log("available_seat_status",availableSeatStatus)
          
            // Update the seat map with the available status
            const updatedRows = {
              rows: initialMap.rows.map((item: any) => ({
                ...item,
                seats: item.seats.map((subItem: any) => {
                  console.log("AVAILABLE SEAT STATUS",availableSeatStatus);
                  const matchedSeat = availableSeatStatus.find(
                    (seatStatus: any) => seatStatus.seat_number === subItem.seat_number && (seatStatus.seat_status === 1 || seatStatus.seat_status === 2)
                  );

                  if (location.state.bookingDetails &&  seatNumbersToUpdate.includes(subItem.seat_number)) {
                    return { ...subItem, status: 4 };
                  }
console.log("matchedSeat",matchedSeat)
                  return matchedSeat ? { ...subItem, status: matchedSeat.seat_status ||  0} : subItem;
                }),
              })),
            };          
          
           // DAMAGED: 2
            // Extract seats with status 1 and update the required states
            const convertedSeatMapData = updatedRows.rows.flatMap((item: any) => 
              item.seats.filter((subItem: any) => subItem.status === 1)
            );
            console.log("Updated rows",updatedRows);
            setCanvasMap(updatedRows);
          }
          
        });
      }
    }, [state, dispatch]);

    const handleGoBack = () => {
      navigate("/");
    };


    useEffect(() => {
      if (canvasMap) {
      }
    }, [canvasMap]);

    const handleVehicle = (val: any, index:any) =>{   
      setReg_no(val.reg_no)
      setActiveIndex(index);
      let { available_seat_status: availableSeatStatus, seat_map_template: initialMap } = val;
      setCanvasMap((prevCanvasMap: any) => {
      let updatedRows = {
        rows: initialMap.rows.map((item: any) => ({
          ...item,
          seats: item.seats.map((subItem: any) => {
            const matchedSeat = availableSeatStatus.find(
              (seatStatus: any) => seatStatus.seat_number === subItem.seat_number &&  (seatStatus.seat_status === 1 || seatStatus.seat_status === 2)
           
            );
            console.log("matchedSeat",matchedSeat)
            return matchedSeat ? { ...subItem, status: matchedSeat.seat_status || 0 } : subItem;
         
          }),
        })),
      }
      console.log('updatedRows',updatedRows);
      return updatedRows;
    });

    const convertedSeatMapData = [];
    setSelectedSeatCount(convertedSeatMapData.length)
    setBookedSeats(convertedSeatMapData)
    };


    const handlePassengerChange =(seatNumber: number, name: string, age: string, gender: string)=>{
      console.log("handlePassengerChange",name)
      setPassengerDetails((prevDetails) => {
        console.log("prevDetails",prevDetails)
        const existingPassenger = prevDetails.find(detail => detail.seatNumber === seatNumber);
        console.log("existingPassenger", existingPassenger)
        if (existingPassenger) {
          const updatedDetails = prevDetails.map((detail) =>
            detail.seatNumber === seatNumber ? { ...detail, name, age, gender } : detail
          );
          console.log('Updated Passenger Details:', updatedDetails); 
          return updatedDetails;
        } else {
          return [...prevDetails, { seatNumber, name, age, gender }];
        }
      });
    }

    const validatePassengerDetails = () => {
      const result = passengerDetails.filter(item => bookedSeats.some(bookedItem => bookedItem.seat_number === item.seatNumber)
      );
      console.log("result", result)
      
      return result.every(detail => detail.name && detail.age && detail.gender);
    };

    

    const handleConfirmDetails = () =>{
      if (bookedSeats.length === 0) {
        setIsShow(true);
        setErrorMessage('Please select seats');
        setTimeout(() => {
          setIsShow(false);
        }, 2000);
        return ; // Prevent booking confirmation if validation fails
      }else if (!validatePassengerDetails()) {
        setIsShow(true);
        setErrorMessage('Please fill in all passenger details correctly.');
    setTimeout(() => {
      setIsShow(false);
    }, 2000);
    return; // Prevent booking confirmation if validation fails
  }

    const formattedpassengerDetails = passengerDetails.filter(item => bookedSeats.some(bookedItem => bookedItem.seat_number === item.seatNumber));
    console.log("formattedpassengerDetails", formattedpassengerDetails)
    const formattedVisitors = formattedpassengerDetails.map(visitor => ({
      visitor_name: visitor.name,
      visitor_age: visitor.age,
      visitor_gender: visitor.gender,
      seat_number: visitor.seatNumber,
      
    }));


    if(location.state.bookingDetails){
      dispatch(UpdateConfirmBookingDetails({
        booking_id : location.state.bookingDetails.booking_id,
        booking_number : location.state.bookingDetails.booking_number,
        vehicle_schedule_id: vehicalSheduleID,
        mobile_number: mobile_number,
        no_of_seats: selectedSeatCount,
        booking_status:"reserved",
        total_amount:0,
        plan_id: plantId||null ,
        visitors:formattedVisitors
      })).then((response:any)=>{
        if (response.payload.meta.status=== 200) {
          console.log("formattedVisitors", formattedVisitors)
          navigate('/confirmTicket', {
            state: {
              visitors:formattedVisitors,
              bookedSeats:selectedSeatCount,
              confirmTicketData: response.payload.data,
              reg_no: reg_no,
              selectedDate : location.state.selectedDate,
              gateId : location.state.gateId,
              mobileNumber : location.state.mobileNumber
            },
          });
        } else {
          console.error("Error in booking:", response.meta);
        }
      });
    }
    else {
       console.log("formattedVisitors", formattedVisitors)
      const bookingDetailsObj ={
        vehicle_schedule_id: vehicalSheduleID,
        mobile_number: mobile_number,
        no_of_seats: selectedSeatCount,
        booking_status:"reserved",
        total_amount:0,
        plan_id: plantId||null ,
        visitors:formattedVisitors
      }
      dispatch(ConfirmBookingDetails(bookingDetailsObj)).then((response:any)=>{
        console.log("response123",response )
        if (response.payload.meta.status=== 200) {
          console.log("formattedVisitors", formattedVisitors)
          navigate('/confirmTicket', {
            state: {
              visitors:formattedVisitors,
              bookedSeats:selectedSeatCount,
              confirmTicketData: response.payload.data,
              reg_no: reg_no,
              selectedDate : location.state.selectedDate,
              gateId : location.state.gateId,
              mobileNumber : location.state.mobileNumber
            },
          });
        } else {
          console.error("Error in booking:", response.meta);
        }
      });
    }
      

        setTimeout(() => {setIsShow(false)}, 2000);
      };
    

    
    const handleSeatMapUpdate = (updatedMap: any) => {
    console.log("sm",updatedMap) ; // This will update the seat map in VehicleDetail component
    const convertedSeatMapData = [];
    updatedMap.rows.map((item: any) => {
      item.seats.map((subItem: any) => {
        console.log("subItem.status",subItem.status)
      if(subItem.status === 4){
        let windowSeatCount = 0;
          convertedSeatMapData?.map((value,index)=>{
            if(value.seat_number===1 || value.seat_number===3 || value.seat_number===4 || value.seat_number===6 || value.seat_number===7 || value.seat_number===9){
              windowSeatCount = windowSeatCount + 1;
            }
          })

          if(convertedSeatMapData.length < 6) {
            if((subItem.seat_number===1 || subItem.seat_number===3 || subItem.seat_number===4 || subItem.seat_number===6 || subItem.seat_number===7 || subItem.seat_number===9) && windowSeatCount<4) {
              convertedSeatMapData.push(subItem);
            }
            else if(convertedSeatMapData.length < 6) {
              convertedSeatMapData.push(subItem);
            }
          }
          
        }
      })
    });
    setSelectedSeatCount(convertedSeatMapData.length)
    setBookedSeats(convertedSeatMapData)
    console.log('sm 1',convertedSeatMapData);
    };

    console.log("BOOKED SEATS",bookedSeats);
    console.log("BOOKED SEATS",passengerDetails);

    return (
      <section className="container">
        <div className="row">
          <div className="col-md-12 border-bottom py-2">
            <header className="d-flex justify-content-between align-items-center">
              <div>
                <p className="textSecondary text-small mb-2">
                  Selected Gate & Date
                </p>
                <h5 className="heading">{selectedGate}</h5>
                <p className="mb-0">{formattedDate}</p>
              </div>
              <div>
                <img src={logo} alt="Logo" width={70} />
              </div>
            </header>
          </div>
        </div>
        <div className="row">
          {vehicle &&
            vehicle?.map((value, index) => {
              // const isActiveDate = isActive(dateObj);
              return (
                <AvailableVehicle
                isActive={index === activeIndex}
                  vehicleNumber={value.reg_no}
                  avaialableCount={value.available_seats}
                  time={value.start_time}
                  onClick= {() => handleVehicle(value, index)}
                />
              );
            })}
        </div>
        <div className="row mt-3">

          <div className="col-md-4">
          <div className="d-flex flex-column align-items-start">
              <h5 style={{ color: '#404756'}}>Select seats</h5>
              <div className="d-flex gap-2 fw-bold">
                <div className="d-flex align-items-center mb-2">
                  <span style={{ width: '20px', height: '20px', marginRight : '5px'}} className="seatSelected"></span>
                  <span>Selected</span>&nbsp;&nbsp;
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span style={{ width: '20px', height: '20px', marginRight : '5px'}} className="seatBooked"></span>
                  <span>Booked</span>
                </div>
              </div>
              <div className="d-flex gap-2 fw-bold">
                <div className="d-flex align-items-center mb-2">
                  <span style={{  width: '20px', height: '20px', marginRight : '5px',border : '1px solid'}} className="seatOpen"></span>
                  <span>Available</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span style={{  width: '20px', height: '20px', marginRight : '5px'}} className="seatBlocked"></span>
                  <span>Not Allowed</span>
                </div>
              </div>
            </div>
  
            {/* <VehicleSeatMap  onSeatSelect={handleSeatSelect}/> */}
            <div style={{ width: "100%", height: "auto", display: "flex" }}>
            {canvasMap && <Canvas initialMap={canvasMap} bookedSeats = {bookedSeats} onMapUpdate={handleSeatMapUpdate} />}
            </div>
          </div>
          <div className="col-md-8">
            <div className="d-flex justify-content-between">
              <button onClick={handleGoBack} className="btnDanger">
                <MdOutlineArrowBack /> Cancel
              </button>
              <button className="btnPrimary" onClick={handleConfirmDetails} >
                Confirm Booking <MdOutlineArrowForward />
              </button>
            </div>
            <div className="totalSelectedSeats">
              <div>
                <h6 className="mb-0 textSecondary">Total Selected Seats</h6>
              </div>
              <div>{selectedSeatCount}</div>
            </div>
            <div className="passengerInfo">
              <form action="">
                {
                  bookedSeats.map((item: any, index) => {
                    const passengerDetail = passengerDetails.find(detail => detail.seatNumber === item.seat_number) || { name: '', age: '', gender: '' };
                    console.log("itemBookedseats", item)
                    return (
                      <PassengerInfo key={item.seat_number}
                      seatNumber={item.seat_number}
                      name={passengerDetail.name}
                      age={passengerDetail.age}
                      gender={passengerDetail.gender}
                      onPassengerChange={handlePassengerChange}
                      onValidate={validatePassengerDetails} 
                      // handleSubmit={handleConfirmDetails}   
                      />
                    )
                  })
                } 
              </form>
            </div>
          </div>
        </div>
      
        <ShowMessage
            onClose={() => setIsShow(false)}
            title={errorMessage}
            type={'error'}
            open={isShow}
          />
      </section>
    );
  };

  export default VehicleSelection;
