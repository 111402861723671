import { createSlice ,PayloadAction} from "@reduxjs/toolkit"
import { getDayTime } from "../actions/DayTimeAction";

interface dayAndDateData{
    dayTime:  any[];
    error: string | null;
    isLoading: boolean;
    message:string;
    
}

const initialState: dayAndDateData ={
    dayTime: [],
    error: null,
    isLoading: false,
    message: ''
}

const dayAndDateSlice = createSlice({
    name:'dayTime',
    initialState,
    reducers:{},
    extraReducers:(builder) => {
        builder
        .addCase(getDayTime.pending, (state) => {
          state.isLoading = false
        })
        .addCase(getDayTime.fulfilled, (state, action: PayloadAction<any>) => {
          state.dayTime= action.payload.data.data;
          state.message = action.payload.data.meta.message
  
          state.isLoading= true;
          console.log("messageSlice",   state.dayTime)
        })
        .addCase(getDayTime.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message || null;
        });

    }


    })

export default dayAndDateSlice.reducer;