import { configureStore } from "@reduxjs/toolkit";
import kioskSlice from "./Slice/kioskSlice";
import dayAndDateSlice from "./Slice/dayAndDateSlice";
import gateSlice from "./Slice/gateSlice";
import vehicleSelectionSlice from "./Slice/vehicleSelectionSlice";
import VerifyMobileSlice from "./Slice/VerifyMobileSlice";
import VerifyOtpSlice from "./Slice/VerifyOtpSlice";
import LoginSlice from "./Slice/LoginSlice";
import ResendTicketSlice from './Slice/ResendTicketSlice'

const store = configureStore({
    reducer:{
        kiosk: kioskSlice,
        dayTime: dayAndDateSlice,
        gates: gateSlice,
        vehicle : vehicleSelectionSlice,
        mobileVerification: VerifyMobileSlice,
        otpVerification:VerifyOtpSlice,
        login: LoginSlice,
        resendTicket: ResendTicketSlice
    },


     // middleware for async api calls

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

})

export default store
// Type the RootState and AppDispatch based on your store
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;