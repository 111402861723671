import axiosInstance from "../api/withJwtServer";
import { actionTypes } from "../actionTypes";
import { createAsyncThunk } from '@reduxjs/toolkit';



export const cancelBookingAction = createAsyncThunk(actionTypes.CANCEL_BOOKING, async (params:any, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/kiosk-cancel-ticket-booking', params);
      console.log('response in confirm ticket booking', response);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });